import { useFormik } from "formik"
import * as yup from "yup"
import React, { useContext, useState } from "react"
import { Space } from "antd"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { TextField, Button, auth } from "@project/shared"
import styled, { useTheme } from "styled-components"
import * as Sentry from "@sentry/node"
import { signInWithEmailAndPassword } from "firebase/auth"
import RestrictedRoute from "../../withRestrictedRoute"
import { AuthContext } from "../../utils"

interface LoginType {
  email: string
  password: string
}

const Container = styled.div`
  background: ${(props) => props.theme.background.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem;
  border-radius: 2px;
  & .text-field {
    & label {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 8px;
    }
  }
  .login-error {
    height: 24px;
    color: #d86464;
    margin-top: 21px;
  }
`

const TextFieldWrapper = styled.div`
  margin-top: 20px;
`

const StyledButton = styled(Button)`
  margin: auto;
  margin-bottom: 30px;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.NSJP};
`

const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
  .ant-input-affix-wrapper {
    padding: 4px 24px;
  }
  .ant-input-prefix {
    margin-right: 8px;
  }
`

const LoginBox = styled(Space)`
  justify-content: center;
  width: 240px;
  height: 79px;
  font-size: ${(props) => props.theme.text.size.s};
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.light};
  font-family: ${(props) => props.theme.font.NSJP};
`
const LoginTextWrapper = styled.div`
  font-size: ${(props) => props.theme.text.size.m};
  margin: 30px 0 0 0;
  color: ${(props) => props.theme.text.color.light};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-family: ${(props) => props.theme.font.NSJ};
  line-height: 49px;
`
const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loginError, setLoginError] = useState("")

  const { loginLoading, setLoginLoading } = useContext(AuthContext)
  /**
   *
   * Accessing global theme
   */

  const theme = useTheme()

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Email address format is incorrect."))
      .required(t("Please enter your email address")),
    password: yup.string().required(t("Please enter your　password")),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const handleLogin = async () => {
    setLoginLoading(true)
    try {
      const data = await signInWithEmailAndPassword(
        auth,
        formik.values.email,
        formik.values.password
      )

      if (!data || !data.user || !auth.currentUser) {
        return
      }
    } catch (error) {
      setLoginLoading(false)
      const errorCode = error.code
      if (errorCode === "auth/user-not-found") {
        setLoginError(t("Your email address or password is incorrect"))
      } else if (errorCode === "auth/wrong-password") {
        setLoginError(t("Your email address or password is incorrect"))
      } else if (errorCode === "auth/user-disabled") {
        setLoginError(
          t(
            "We could not login you at this moment. Please contact your administration for inquiry"
          )
        )
      } else {
        setLoginError("An error has occurred. Please try again later.")
      }
      Sentry.captureException(error)
    }
  }

  return (
    <>
      <Head>
        <title>{`${t("Login")} | かきおき`}</title>
      </Head>
      <Container>
        <LoginWrapper>
          <LoginBox align="center">
            <div>
              <img
                width={"240px"}
                height={"79px"}
                src="/assets/imgs/footer-logo.svg"
                alt="Logo"
              />
            </div>
          </LoginBox>
          <LoginTextWrapper>{t("Login to dashboard")}</LoginTextWrapper>
          <div className="login-error">{loginError}</div>
          <TextFieldWrapper>
            <form onSubmit={formik.handleSubmit}>
              <InputFieldWrapper>
                <TextField
                  name="email"
                  bgcolor="#fff"
                  error={formik.touched.email && formik.errors.email}
                  onChange={(evt) => {
                    formik.handleChange(evt)
                    Boolean(loginError) && setLoginError("")
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("Email address")}
                  className="text-field"
                  disableboxshadow
                  height="60px"
                  width="500px"
                  borderradius="10px"
                  prefix={
                    <img src="/assets/icons/mail.svg" width={24} height={24} />
                  }
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <TextField
                  bgcolor="#fff"
                  name="password"
                  type="password"
                  error={formik.touched.password && formik.errors.password}
                  onChange={(evt) => {
                    formik.handleChange(evt)
                    Boolean(loginError) && setLoginError("")
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("Password")}
                  disableboxshadow
                  className="text-field"
                  height="60px"
                  width="500px"
                  borderradius="10px"
                  prefix={
                    <img
                      src="/assets/icons/lock_hoso.svg"
                      width={24}
                      height={24}
                    />
                  }
                />
              </InputFieldWrapper>
              <StyledButton
                background={theme?.button.primary}
                width="200px"
                height="60px"
                htmlType="submit"
                loading={loginLoading}
              >
                {t("Login")}
              </StyledButton>
            </form>
          </TextFieldWrapper>
        </LoginWrapper>
      </Container>
    </>
  )
}

export default RestrictedRoute(LoginPage)
